<template>
<div class="input-group">
  <label>{{ label }}</label>
  <div v-if="false" class="slider slider-photos">
    <div class="items">
      <div class="item" :style="{ backgroundImage: `url(${require('@/assets/img/user-photos.jpg')}` }">
        <span class="remove">
          <svg width="14" height="16" viewBox="0 0 14 16" fill="none">
            <path d="M12.25 2H9.5V1.5C9.5 0.671562 8.82844 0 8 0H6C5.17156 0 4.5 0.671562 4.5 1.5V2H1.75C1.05966 2 0.5 2.55966 0.5 3.25V4.25C0.5 4.52616 0.723844 4.75 1 4.75H13C13.2762 4.75 13.5 4.52616 13.5 4.25V3.25C13.5 2.55966 12.9403 2 12.25 2ZM5.5 1.5C5.5 1.22438 5.72437 1 6 1H8C8.27563 1 8.5 1.22438 8.5 1.5V2H5.5V1.5Z" fill="white"/>
            <path d="M1.44921 5.75C1.35999 5.75 1.2889 5.82456 1.29315 5.91369L1.70565 14.5712C1.74377 15.3725 2.4019 16 3.20377 16H10.7969C11.5988 16 12.2569 15.3725 12.295 14.5712L12.7075 5.91369C12.7118 5.82456 12.6407 5.75 12.5515 5.75H1.44921ZM9.00034 7C9.00034 6.72375 9.22409 6.5 9.50034 6.5C9.77659 6.5 10.0003 6.72375 10.0003 7V13.5C10.0003 13.7762 9.77659 14 9.50034 14C9.22409 14 9.00034 13.7762 9.00034 13.5V7ZM6.50034 7C6.50034 6.72375 6.72409 6.5 7.00034 6.5C7.27659 6.5 7.50034 6.72375 7.50034 7V13.5C7.50034 13.7762 7.27659 14 7.00034 14C6.72409 14 6.50034 13.7762 6.50034 13.5V7ZM4.00034 7C4.00034 6.72375 4.22409 6.5 4.50034 6.5C4.77659 6.5 5.00034 6.72375 5.00034 7V13.5C5.00034 13.7762 4.77659 14 4.50034 14C4.22409 14 4.00034 13.7762 4.00034 13.5V7Z" fill="white"/>
          </svg>
        </span>
        <span class="label-main">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M11.476 0.781023C10.9345 0.489446 10.3305 1.05177 9.97643 1.385C9.16421 2.17643 8.47692 3.09281 7.70629 3.92589C6.85239 4.84228 6.06096 5.75866 5.18623 6.65426C4.68638 7.1541 4.14488 7.6956 3.81165 8.32041C3.06188 7.59144 2.41625 6.80001 1.58317 6.15441C0.979187 5.69622 -0.0205067 5.36299 0.000320301 6.46681C0.0419742 7.90391 1.31242 9.4451 2.24963 10.4239C2.64534 10.8405 3.16602 11.2778 3.77 11.2987C4.49894 11.3403 5.24871 10.4656 5.68608 9.98657C6.45671 9.15349 7.08152 8.21624 7.7896 7.36237C8.70599 6.23772 9.6432 5.13386 10.5388 3.98837C11.1011 3.28026 12.8714 1.53076 11.476 0.781023ZM0.916672 6.38351C0.895845 6.38351 0.875018 6.38351 0.833364 6.4043C0.750057 6.38351 0.687576 6.36265 0.604268 6.32099C0.666749 6.27934 0.770884 6.30016 0.916672 6.38351Z" fill="white"/>
          </svg>
          Главное
        </span>
      </div>
    </div>
    <div class="nav">
      <span class="item prev">
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
          <path d="M0.829551 8.57113L8.02468 15.7639C8.34039 16.0788 8.85191 16.0788 9.16842 15.7639C9.48414 15.4489 9.48414 14.9374 9.16842 14.6225L2.54401 8.00049L9.16762 1.37846C9.48334 1.06354 9.48334 0.552029 9.16762 0.236312C8.85191 -0.0786079 8.3396 -0.0786079 8.02388 0.236312L0.828754 7.42898C0.517887 7.74064 0.517887 8.26019 0.829551 8.57113Z" fill="white"/>
        </svg>
      </span>
      <span class="item next">
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
          <path d="M9.17045 8.57113L1.97532 15.7639C1.65961 16.0788 1.14809 16.0788 0.831578 15.7639C0.515861 15.4489 0.515861 14.9374 0.831578 14.6225L7.45599 8.00049L0.832376 1.37846C0.516659 1.06354 0.516659 0.552029 0.832376 0.236312C1.14809 -0.0786079 1.6604 -0.0786079 1.97612 0.236312L9.17125 7.42898C9.48211 7.74064 9.48211 8.26019 9.17045 8.57113Z" fill="white"/>
        </svg>
      </span>
    </div>
  </div>
  <span class="button-add-file">
    Добавить
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path d="M13.2301 1.38256C12.717 0.863711 12.0986 0.593855 11.4396 0.602496C10.823 0.61048 10.218 0.872488 9.7361 1.34026L9.73156 1.34466L3.47597 7.70468C2.85004 8.33717 2.84988 9.25543 3.47559 9.88808C3.77899 10.1949 4.16604 10.3638 4.56551 10.3638C4.56556 10.3638 4.56564 10.3638 4.5657 10.3638C4.97 10.3638 5.3596 10.1948 5.66462 9.88625L9.98829 5.46908L9.20667 4.70403L4.88491 9.11925C4.78877 9.21652 4.67537 9.27008 4.56554 9.27008C4.45747 9.27011 4.35236 9.21928 4.25319 9.11901C3.96531 8.82791 4.1574 8.57096 4.25453 8.47285L10.5021 2.12098C11.0992 1.54501 11.8641 1.55682 12.4525 2.15168C13.1505 2.85751 12.9557 3.68613 12.4529 4.19421L5.34152 11.5387C4.85286 12.0322 4.23459 12.304 3.60036 12.304C3.60025 12.304 3.60014 12.304 3.60003 12.304C2.96601 12.304 2.3645 12.0347 1.86061 11.5253C0.681082 10.3327 1.01052 8.93198 1.86045 8.07245L7.24979 2.68431L6.47651 1.91087L1.08596 7.30018L1.08391 7.30226C0.407945 7.98534 0.0233826 8.84672 0.00107015 9.72765C-0.0227463 10.667 0.351371 11.5546 1.08301 12.2944C1.78678 13.0059 2.68062 13.3978 3.60011 13.3978C3.60022 13.3978 3.60046 13.3978 3.60057 13.3978C4.53012 13.3976 5.42533 13.0098 6.12128 12.3057L13.2327 4.96117C14.2567 3.92413 14.2558 2.41962 13.2301 1.38256Z" fill="#04B67D"/>
    </svg>
  </span>
</div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="sass" scoped>
.input-group
  width: 100%
  >label
    font-size: 16px
    font-weight: 700
    margin-bottom: 3px
  .button-add-file
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    height: 40px
    background: #f2f3f5
    border-radius: 5px
    border: 1px solid #04B67D
    font-size: 15px
    font-weight: 600
    color: #04B67D
    padding: 0 10px 0 15px
    cursor: pointer
</style>